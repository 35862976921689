import { isString } from '@borg/utils';

export default defineNuxtRouteMiddleware((to, from) => {
  if (to.fullPath === from.fullPath && process.client) {
    // stops the double count of views, we want to count the server views from 'bots' also
    return;
  }
  const userInfo = useUserInfo();
  const jobId = to.params.jobId;
  const source = to.query.source?.toString() ?? 'no_slug';

  if (jobId && isString(jobId)) {
    userInfo.setSeen(jobId);
    jobsService.trackPageVisit(jobId, source);
  }
});
